<style lang="scss" scoped>
.page-member-list {
    .user-avatar {
        width: 50px;
        height: 50px;
        background-size: cover;
        border-radius: 50%;
    }
}
</style>

<template>
    <div class="page-member-list">
        <div class="toolbars mrgb5">
            <div class="buttons mrgb5">
                <div class="fl"></div>
                <div class="fr">
                    <el-date-picker class="c-datetime-range mrgr5 mrgb5" v-model="selectedTime" type="datetimerange"
                        :picker-options="pickerOptions" :default-time="['00:00:00', '23:59:59']"
                        @change="_search" range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" size="medium" align="right">
                    </el-date-picker>
                    <el-checkbox class="mrgr5 mrgb5" v-model="filter.onlyShowAgent" @change="_search()" label="只看代理" border size="medium"></el-checkbox>
                    <el-select class="mrgr5 mrgb5" v-model="filter.clientType" placeholder="会员来源" @change="_search()" clearable size="medium">
                        <el-option :value="item.value" :label="item.name" v-for="item in clientTypeOptions" :key="item.value"></el-option>
                    </el-select>
                    <el-input class="c-el-input mrgr5 mrgb5" placeholder="会员昵称/id/代理名/手机号" clearable v-model="filter.keywords" size="medium"></el-input>
                    <el-button type="primary" size="medium" @click="_search()"><i class="fa fa-search"></i> 查询</el-button>
                </div>
                <div class="clearb"></div>
            </div>
        </div>
        <div>
            <el-table :data="tableData" border fit highlight-current-row row-key="id" @sort-change="sortChange">
                <el-table-column prop="id" label="ID" width="80"></el-table-column>
                <el-table-column label="头像" width="80">
                    <template slot-scope="scope">
                        <div class="user-avatar" :style="`background-image:url(${scope.row.head})`"></div>
                    </template>
                </el-table-column>
                <el-table-column label="昵称">
                    <template slot-scope="scope">
                        {{scope.row.name}}
                        <div>
                            <template v-if="scope.row.tags && scope.row.tags.length > 0">
                                <el-tag class="mrgr5" type="warning" size="mini" v-for="item in scope.row.tags" :key="item">{{item}}</el-tag>
                            </template>
                            <el-tag type="warning" size="mini" v-else>普通会员</el-tag>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="来源">
                    <template slot-scope="scope">
                        <el-tag type="success" size="mini" v-if="scope.row.clientTypeDesc">{{ scope.row.clientTypeDesc }}</el-tag>
                        <template v-else>未知</template>
                    </template>
                </el-table-column>
                <el-table-column prop="phoneNumber" label="手机号"></el-table-column>
                <el-table-column prop="balance" label="余额" width="120" sortable="custom"></el-table-column>
                <el-table-column prop="creationTime" label="创建时间" sortable="custom" width="160"></el-table-column>
                <el-table-column label="操作" width="100px" fixed="right">
                    <template slot-scope="scope">
                        <el-dropdown trigger="click">
                            <el-button type="primary" plain size="mini">更多
                                <i class="el-icon-arrow-down el-icon--right"></i>
                            </el-button>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item @click.native="_query(scope.row)">查看详情</el-dropdown-item>
                                <el-dropdown-item @click.native="skipToRoute(`/member/capitallogs?id=${scope.row.id}`)">余额日志</el-dropdown-item>
                                <el-dropdown-item @click.native="openChangeBalance(scope.row)">增加余额</el-dropdown-item>
                                <el-dropdown-item @click.native="skipToRoute(`/order/list?memberId=${scope.row.id}`)">查看订单</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination-container" v-if="isPagination">
                <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
                    :current-page="filter.page" :page-sizes="appPageSizes" :page-size="filter.size"
                    layout="total, sizes, prev, pager, next, jumper" :total="total">
                </el-pagination>
            </div>
        </div>
        <!--详情-->
        <detail :ref="refDetail"></detail>
        <change-balance :ref="refChangeBalance" @refreshData="_search()"></change-balance>
    </div>
</template>

<script>
import enumConfigs from "@/mixins/enumConfigs"
import * as funMember from "@/api/member/index"
import detail from "./components/detail"
import ChangeBalance from "./components/changeBalance"
import moment from "moment"
export default {
    name: "pageMemberList",
    mixins: [enumConfigs],
    components: { detail, ChangeBalance },
    data() {
        const that = this
        return {
            refDetail: "refMemberListToDetail",
            refChangeBalance: "refMemberListToChangeBalance",
            tableItems: {},
            tableData: [],
            total: null,
            isPagination: false,
            filter: {
                page: 1,
                size: window.$common.appPageSize,
                keywords: "",
                onlyShowAgent: false,
                clientType: null,
                sortDirection: 0,
                sortField: null,
                startDate: null,
                endDate: null
            },
            selectedTime: [],
            pickerOptions: {
                shortcuts: [
                    {
                        text: "今天",
                        onClick: (picker) => {
                            let timeArr = that.setPickerTime(picker, 1, "days")
                            that.setQueryTime(timeArr)
                        }
                    },
                    {
                        text: "近一周",
                        onClick: (picker) => {
                            let timeArr = that.setPickerTime(picker, 1, "weeks")
                            that.setQueryTime(timeArr)
                        }
                    },
                    {
                        text: "近一月",
                        onClick(picker) {
                            let timeArr = that.setPickerTime(picker, 1, "months")
                            that.setQueryTime(timeArr)
                        }
                    },
                    {
                        text: "近三月",
                        onClick(picker) {
                            let timeArr = that.setPickerTime(picker, 3, "months")
                            that.setQueryTime(timeArr)
                        }
                    },
                    {
                        text: "近半年",
                        onClick(picker) {
                            let timeArr = that.setPickerTime(picker, 6, "months")
                            that.setQueryTime(timeArr)
                        }
                    },
                    {
                        text: "近一年",
                        onClick(picker) {
                            let timeArr = that.setPickerTime(picker, 1, "years")
                            that.setQueryTime(timeArr)
                        }
                    }
                ]
            }
        }
    },
    computed: {
        clientTypeOptions() {
            return this.arrClientType
        }
    },
    created() {
        if(this.$route.query.time=="0"){
            this.getTime(0)
        }else if(this.$route.query.time=="1"){
            this.getTime(1)
        }else if(this.$route.query.time=="2"){
            this.getTime(7)
        }else if(this.$route.query.time=="3"){
            this.getTime(30)
        }else if(this.$route.query.id){
            this.filter.keywords = this.$route.query.id
        }
        this.getDataList()
    },
    methods: {
        setQueryTime(timeArr) {
            this.filter.startDate = timeArr[0] || ""
            this.filter.endDate = timeArr[1] || ""
        },
        handleSizeChange(val) {
            this.filter.size = val
            this.getDataList()
        },
        handleCurrentChange(val) {
            this.filter.page = val
            this.getDataList()
        },
        setSelectedTime() {
            let psTime = ""
            let peTime = ""
            if (this.selectedTime && this.selectedTime[0] && moment(this.selectedTime[0]).isValid()) {
                psTime = moment(this.selectedTime[0]).format("YYYY-MM-DD HH:mm:ss")
            }
            if (this.selectedTime && this.selectedTime[1] && moment(this.selectedTime[1]).isValid()) {
                peTime = moment(this.selectedTime[1]).format("YYYY-MM-DD HH:mm:ss")
            }
            this.filter.startDate = psTime
            this.filter.endDate = peTime
            this.selectedTime = [this.filter.startDate, this.filter.endDate]
        },
        async getDataList() {
            this.setSelectedTime()
            window.$common.fullLoading()
            funMember.QueryMemberList(this.filter).then(response => {
                window.$common.closeFullLoading()
                this.tableItems = response
                this.total = response.totalCount
                this.isPagination = response.totalCount > 0 || false
                if (response && response.items && response.items.length > 0) {
                    this.tableData = response.items
                } else {
                    this.tableData = []
                }
            })
        },
        _query(row) {
            this.$refs[this.refDetail].show(row)
        },
        openChangeBalance(row) {
            this.$refs[this.refChangeBalance].show(row)
        },
        _search() {
            this.filter.page = 1
            this.getDataList()
        },
        // 处理传过来的时间数字
        getTime(days) {
            if (days == 1) {
                this.filter.startDate = moment().subtract(days, "days").format("YYYY-MM-DD 00:00:00")
                this.filter.endDate = moment().subtract(days, "days").format("YYYY-MM-DD 23:59:59")
                this.selectedTime = [this.filter.startDate, this.filter.endDate]
            } else {
                this.filter.startDate = moment().subtract(days, "days").format("YYYY-MM-DD 00:00:00")
                this.filter.endDate = moment().format("YYYY-MM-DD 23:59:59")
                this.selectedTime = [this.filter.startDate, this.filter.endDate]
            }
        },
        async sortChange(e) {
            if (e.order) {
                this.filter.sortField = e.prop
                if (e.order == "ascending") {
                    this.filter.sortDirection = 0
                } else {
                    this.filter.sortDirection = 1
                }
            } else {
                this.filter.sortDirection = null
                this.filter.sortField = null
            }
            this.getDataList()
        }
    }
}
</script>